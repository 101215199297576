import {
	isBlank
}
from 'voUtils/tools.js';

import {
	favCount, onFavUpdate
}
from 'voUtils/FavStore.js';

export default {

	data: {
		name: undefined,
	},
	computed: {
		mailto: function () {
			return `mailto:${this.emailAddress}`;
		},
		emailAddress: function () {
			return (VOFFICE.contactEmail || ['info', 'test.de']).join('@');
		}

	},

	mounted: function () {
		onFavUpdate(() => {
			this.$forceUpdate();
		});
		var totalBlog = $(".header-blog").length;
		if (totalBlog <= 0 && $("#nav")) {
			$("#nav").removeClass('header-alert');
		}
	},

	methods: {

		closeWinterBanner: function () {
			var d = new Date();
			d.setTime(d.getTime() + (15 * 60 * 1000)); //15 minutes
			var expires = "expires=" + d.toUTCString();
			document.cookie = "winterbanner=true" + ";" + expires + ";path=/";
			$(".winter-banner").hide();
			/*if ($("#nav")) {
				$("#nav").removeClass('header-alert');
			}
			if ($("#covid-button")) {
				$("#covid-button").removeClass('header-alert');
			}
			if ($("#searchpagelayout")) {
				$("#searchpagelayout").removeClass('header-alert');
			}
			if ($("#searchpagelayout")) {
				$("#searchpagelayout").removeClass('header-alert');
			}
			if ($("#unitpagelayout")) {
				$("#unitpagelayout").removeClass('header-alert');
			}*/
			$("body").addClass('no-header');

		},
		canShowWinterBanner: function () {
			console.log('------------- canShowWinterBanner ----------------');
			var name = "winterbanner=";
			var canShow = true;
			var ca = document.cookie.split(';');
			for (var i = 0; i < ca.length; i++) {
				var c = ca[i];
				while (c.charAt(0) == ' ') {
					c = c.substring(1);
				}
				if (c.indexOf(name) == 0) {
					canShow = false;
				}
			}
			//console.log('returning true;;;;;');
			//return true;
			if (!canShow) {
				$("body").addClass('no-header');
			}
			return canShow;
		},

		navbarToggle: function () {

			$('.main-menu').slideToggle();
			$('.navbar-v1').removeClass('open-by-mob');

		},

		favCount: favCount,
		goSearch: function () {
			if (isBlank(this.name)) {
				window.location.href = 's';
			} else {
				window.location.href = 's#?name=' + encodeURIComponent(this.name);
			}
		}



	}
};