<template>
    <div class="">

        <span class="price-start"
            v-html="getPriceText"></span>

        <span class="more-price-info"> 
        / {{voMsg('minrent.week')}}
        </span>

    </div>

</template>
<script>
    import V from 'voUtils/V.js';
    import DateEx from 'voUtils/DateEx.js';

    export default {
    	voVueComponent: 'minrent',
    	props: {
    		pricelist: Array,
    		minrent: Number,
    		rentcalculation: String
    	},
    	data: function() {
    		return {
    			totalStars: 5
    		};
    	},
    	computed: {
    		getPriceText: function() {
    			var priceText = '';
    			var minrentWeek = 0;
    			var priceCleaning = 0;

    			var total = 0;

    			console.log('Pricelist::', this.pricelist);
    			console.log('Minrent::', this.minrent);

    			if (this.minrent && this.pricelist) {
    				if (this.rentcalculation != 'WEEK') {
    					minrentWeek = this.minrent * 7;
    				} else {
    					minrentWeek = this.minrent
    				}

    				//priceText += 'minrent:' + minrentWeek;

    				total = this.minrent
    				console.log('this.minrent:' + this.minrent);
    				for (var i = 0; i < this.pricelist.length; i++) {
    					console.log('this.pricelist[i]::' + this.pricelist[i].service);
    					if (this.pricelist[i].type == 'FINALCLEANING') {
    						//priceCleaning = this.pricelist[i].value;
    						//console.log('cleaning::' + this.pricelist[i].value)
    						if (priceCleaning == 0 || priceCleaning >= this.pricelist[i].value) {
    							priceCleaning = this.pricelist[i].value;
    						}

    					} else if (this.pricelist[i].service == 44267) {
    						//console.log('booking::' + this.pricelist[i].value)
    						total += this.pricelist[i].value;
    					} else if (this.pricelist[i].service == 44122) {
    						//console.log('swimming::' + this.pricelist[i].value);
    						total += (this.pricelist[i].value * 7);
    					} else if (this.pricelist[i].service == 44120) {
    						//console.log('Washing::' + this.pricelist[i].value);
    						total += (this.pricelist[i].value * 7);
    					}
    				}
    				total += priceCleaning;
    				priceText += ' ' + this.voMsg("minrent.ab") + ' <span class="discount-price h3"><strong>';
    				priceText += Number(total / 100).toLocaleString("de-DE", {
    					minimumFractionDigits: 2
    				}) + " CHF";
    				priceText += '</span><strong>'

    			}

    			return priceText;
    		},
    		getMinRentText: function() {
    			var minRentText = '';

    			if (this.minrent) {
    				minRentText += Number(this.minrent / 100).toLocaleString("de-DE", {
    					minimumFractionDigits: 2
    				}) + " CHF";
    			}

    			return minRentText;
    		}
    	}
    };
</script>
<style scoped>
    .pricehelp {
    	font-size: 13px;
    	color: #666666;
    }
</style>